import { FormContext, FormEvent } from 'src/common/types';
import { sortQuotesByLowerPrice } from 'src/utils';
import {
  fetchLo,
  fetchQuotes,
  getDwellingDetails,
  getKCSession,
  getLoanData,
  getQuotes,
  saveContactInformation,
} from 'src/utils/fetchActions';
import {
  ActionFunctionMap,
  assign,
  ConditionPredicate,
  MachineConfig,
} from 'xstate';
import formContext from '../context';

export const disclaimerNode: MachineConfig<FormContext, any, FormEvent> = {
  id: 'disclaimer',
  initial: 'idle',
  on: { BACK: { target: 'getUserInfo' } },
  states: {
    fetching: {
      on: {
        SUBMIT: {
          actions: { type: 'changeModal', modal: 'loading' },
        },
        SUBMIT_NO_SPLASH: {
          actions: { type: 'changeModal', modal: 'loading' },
        },
      },
      states: {
        fetchLo: {
          invoke: {
            id: 'fetchLoanOfficer',
            src: (context: FormContext) =>
              fetchLo(
                context.agent,
                context.team,
                context.address,
                context.partnerId
              ),
            onDone: [
              {
                target: 'fetchOptimalBlue',
                cond: 'hasZipcode',
                actions: 'setLoID',
              },
              { target: '#disclaimerIdle', actions: 'setLoID' },
            ],
            onError: [
              {
                target: 'fetchOptimalBlue',
                cond: 'hasZipcode',
                actions: 'setLoID',
              },
              {
                target: '#disclaimerIdle',
                actions: 'setLoID',
              },
            ],
          },
        },
        fetchOptimalBlue: {
          invoke: {
            id: 'fetchOptimalBlueId',
            src: (context: FormContext) =>
              getLoanData(
                context.answers.askingPrice,
                context.address.zip.number,
                context.flowInfo.sessionID
              ),
            onDone: [
              // this code is used to activate the splash page
              // {
              //   target: 'fetchKCSession',
              //   cond: 'canFetchKCSession',
              //   actions: 'setRatesInfo',
              // },
              // {
              //   target: 'fetchKCSession',
              //   cond: 'canFetchKCSessionAgain',
              //   actions: 'setRatesInfo',
              // },
              { target: '#disclaimerIdle', actions: 'setRatesInfo' },
            ],
            onError: [
              // { target: 'fetchKCSession', cond: 'canFetchKCSession' },
              // { target: 'fetchKCSession', cond: 'canFetchKCSessionAgain' },
              { target: '#disclaimerIdle' },
            ],
          },
        },
        fetchKCSession: {
          invoke: {
            id: 'fetchKCSessionData',
            src: (context: FormContext) =>
              getKCSession(
                context.kellerCovered.lead,
                context.kellerCovered.sourceIdentifier
              ),
            onDone: {
              target: 'fetchDwellingDetails',
              actions: [
                'saveKCSession',
                // We we'll clean all posible previous dwelling details that might be in the context.
                'cleanDwellingDetails',
              ],
            },
            onError: {
              target: '#disclaimerIdle',
            },
          },
        },
        fetchDwellingDetails: {
          invoke: {
            id: 'fetchDwellingDetailsData',
            src: (context: FormContext) => getDwellingDetails(context),
            onDone: [
              {
                target: 'saveContactInformation',
                actions: 'saveDwellingDetails',
                cond: 'hasKCDwellingDetails',
              },
              {
                target: '#disclaimerIdle',
              },
            ],
            onError: {
              target: '#disclaimerIdle',
            },
          },
        },
        saveContactInformation: {
          invoke: {
            id: 'saveContactInformationData',
            src: (context: FormContext) => saveContactInformation(context),
            onDone: {
              // target: 'fetchInitialQuotes',
              // actions: 'saveContactID',   // commented out because it's not used
              target: '#disclaimerIdle',
            },
            onError: {
              target: '#disclaimerIdle',
            },
          },
        },
        fetchInitialQuotes: {
          invoke: {
            id: 'fetchInitialQuotesData',
            src: (context: FormContext) => getQuotes(context),
            onDone: {
              target: '#fetchingQuotes',
              actions: 'saveQuoteRequestId',
            },
            onError: {
              target: '#disclaimerIdle',
            },
          },
        },
      },
    },
    idle: {
      id: 'disclaimerIdle',
      always: [
        { target: '#submitData', cond: ctx => ctx.activeModal === 'loading' },
      ],
      on: { SUBMIT: '#submitData', SUBMIT_NO_SPLASH: '#submitData' },
    },
    fetchingQuotes: {
      id: 'fetchingQuotes',
      on: {
        SAVE_QUOTES: { actions: 'saveQuotes' },
        SET_ELAPSED_TIME: { actions: 'setElapsedTime' },
        SET_QUOTES_STATUS: { actions: 'setQuotesStatus' },
        GO_TO_QUOTES: { target: '#quotes' },
        SUBMIT: [
          {
            actions: { type: 'changeModal', modal: 'loading' },
            cond: ctx =>
              ctx.quotes.elapsedTime < 10000 &&
              ctx.quotes.quotesStatus !== 'Succeeded',
          },
          { target: '#quotes', cond: 'hasQuotes' },
          { target: '#submitData' },
        ],
        SUBMIT_DATA: '#submitData',
        SUBMIT_NO_SPLASH: '#submitData',
      },
      invoke: {
        src: context => callback => {
          const {
            kellerCovered: { sessionID, contactID },
            quotes: { quoteRequestID },
          } = context;
          let interval: ReturnType<typeof setInterval>;

          if (sessionID && contactID && quoteRequestID) {
            interval = setInterval(async () => {
              const currentQuotes = await fetchQuotes(context);
              callback({ type: 'SET_ELAPSED_TIME', time: 3000 });
              if (currentQuotes) {
                const {
                  response: { rates, status },
                } = currentQuotes;
                if (
                  rates &&
                  rates.length >= +process.env.NEXT_PUBLIC_MIN_QUOTES!
                ) {
                  const sortedQuotes = rates.sort(sortQuotesByLowerPrice);
                  callback({ type: 'SAVE_QUOTES', quotes: sortedQuotes });
                }

                callback({ type: 'SET_QUOTES_STATUS', status });
                if (status === 'Succeeded') {
                  clearInterval(interval);
                }
              }
            }, 3000);
            // check if the timer reachs the limit
          }
          // Perform cleanup
          return () => clearInterval(interval);
        },
      },
    },
    hist: {
      type: 'history' as 'history',
    },
  },
};

export const disclaimerActions: ActionFunctionMap<FormContext, any> = {
  setLoID: assign((context, event) => ({
    ...context,
    agent: {
      ...context.agent,
      loanManagerId: event.data.id || '',
      loanManagerFirstName: event.data.firstName || '',
      loanManagerLastName: event.data.lastName || '',
      loanManagerEmail: event.data.email || '',
    },
  })),
  setRatesInfo: assign((context, event) => ({
    ...context,
    rates: {
      ...context.rates,
      ...event.data,
    },
  })),
  setKellerCoveredInfo: assign((context, _event, { action }) => ({
    ...context,
    kellerCovered: {
      ...context.kellerCovered,
      [action.key]: action.value,
    },
  })),
  cleanDwellingDetails: assign((context: FormContext) => ({
    ...context,
    dwellingDetails: {
      ...formContext.dwellingDetails,
    },
  })),
  saveKCSession: assign((context, event) => {
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      id,
      updated_at: updatedAt,
    } = event.data;
    return {
      ...context,
      kellerCovered: {
        ...context.kellerCovered,
        sessionID: id,
        accessToken,
        refreshToken,
        updatedAt,
      },
      quotes: {
        quotesLoading: false,
        quotesList: null,
        quoteRequestID: null,
        quoteSelected: null,
        quotesStatus: null,
        elapsedTime: 0,
        saveForLater: false,
        isChecklist: false,
        ownsHomeInsurance: false,
      },
    };
  }),
  saveDwellingDetails: assign((context, event) => {
    const {
      bathrooms,
      bedrooms,
      stories,
      square_feet: squareFeet,
      replacement_cost: replacementCost,
      year_built: yearBuilt,
      fire_hydrant_distance_answer: fireHydrantDistanceAnswer,
      fire_station_distance_answer: fireStationDistanceAnswer,
    } = event.data;
    return {
      ...context,
      dwellingDetails: {
        ...context.dwellingDetails,
        noOfStories: stories,
        totalBathCount: bathrooms,
        numberOfBedrooms: bedrooms,
        squareFeet,
        replacementCost,
        yearBuilt,
        fireHydrantDistanceAnswer,
        fireStationDistanceAnswer,
      },
    };
  }),
  saveContactID: assign((context, event) => {
    const { id } = event.data;
    return {
      ...context,
      kellerCovered: {
        ...context.kellerCovered,
        contactID: id,
        contextChanged: false,
      },
    };
  }),
  saveQuotes: assign((context, event) => ({
    ...context,
    quotes: {
      ...context.quotes,
      quotesList: event.quotes,
    },
  })),
  setQuotesStatus: assign((context, event) => ({
    ...context,
    quotes: {
      ...context.quotes,
      quotesStatus: event.status,
    },
  })),
  saveQuoteRequestId: assign((context, event) => {
    const { quote_request_id: quoteRequestID } = event.data;
    return {
      ...context,
      quotes: {
        ...context.quotes,
        quoteRequestID,
        quotesLoading: true,
      },
    };
  }),
  setElapsedTime: assign({
    quotes: (context: FormContext, event) => ({
      ...context.quotes,
      elapsedTime: context.quotes.elapsedTime + event.time,
    }),
  }),
};

export const disclaimerGuards: Record<
  string,
  ConditionPredicate<FormContext, any>
> = {
  hasZipcode: context => !!context.address.zip.number,
  hasKCDwellingDetails: (_, event) => event.data.year_built !== null,
  canFetchKCSession: context =>
    context.kellerCovered.sessionID === null &&
    context.answers.buyOrRefinance === 'buy' &&
    context.address.address !== '' &&
    context.answers.getAgentInfo === 'has KW agent' &&
    context.agent.KWUID !== '' &&
    context.agent.loanManagerId !== '' &&
    !!context.userInfo.dateOfBirth &&
    context.flowInfo.aba === 'on',
  canFetchKCSessionAgain: context =>
    context.kellerCovered.sessionID !== null &&
    context.answers.buyOrRefinance === 'buy' &&
    context.address.address !== '' &&
    context.answers.getAgentInfo === 'has KW agent' &&
    context.agent.KWUID !== '' &&
    context.agent.loanManagerId !== '' &&
    !!context.userInfo.dateOfBirth &&
    context.flowInfo.aba === 'on',
  // && context.kellerCovered.contextChanged,
  disclaimerCallsStillRunning: context =>
    !context.kellerCovered.allCallsFinished,
  hasQuotes: context =>
    context.quotes.quotesList
      ? context.quotes.quotesList.length >= +process.env.NEXT_PUBLIC_MIN_QUOTES!
      : false,
};
