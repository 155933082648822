import {
  BuyOrRefinance,
  FormContext,
  FormEvent,
  Origin,
} from 'src/common/types';
import { getOrUpdateSessionId } from 'src/utils/fetchActions';
import {
  actions,
  assign,
  createMachine,
  MachineConfig,
  MachineOptions,
  spawn,
} from 'xstate';
import agentInfoMachine from './agentInfoMachine';
import formContext from './context';
import saveProgressMachine from './saveProgressMachine';
import { askingPriceActions, askingPriceNode } from './stepNodes/askingPrice';
import { buyOrRefiActions, buyOrRefiNode } from './stepNodes/buyOrRefinance';
import {
  disclaimerActions,
  disclaimerGuards,
  disclaimerNode,
} from './stepNodes/disclaimerNode';
import {
  doesThisLookRightActions,
  doesThisLookRightNode,
} from './stepNodes/doesThisLookRight';
import {
  getUserInfoActions,
  getUserInfoGuards,
  getUserInfoNode,
} from './stepNodes/getUserInfo';
import { homeTypeActions, homeTypeNode } from './stepNodes/homeType';
import {
  initializingActions,
  initializingGuards,
  initializingNode,
} from './stepNodes/initializing';
import {
  propertyAddressActions,
  propertyAddressNode,
} from './stepNodes/propertyAddress';
import { propertyUseActions, propertyUseNode } from './stepNodes/propertyUse';
import {
  purchaseDateActions,
  purchaseDateNode,
} from './stepNodes/purchaseDate';
import { quotesActions, quotesGuards, quotesNode } from './stepNodes/quotes';
import {
  submitDataActions,
  submitDataGuards,
  submitDataNode,
} from './stepNodes/submitDataNode';
import { whatIsYourCurrentAddressNode } from './stepNodes/whatIsYourCurrentAddress';

const { choose } = actions;

const mainMachineConfig: MachineConfig<FormContext, any, FormEvent> = {
  id: 'mainMachine',
  initial: 'init',
  context: formContext,
  states: {
    init: { ...initializingNode },
    idle: {
      id: 'idle',
      on: {
        START_FLOW: [
          { target: 'steps.hist', cond: 'resumeFlow' },
          { target: 'steps', actions: { type: 'resetForm' } },
        ],
      },
    },
    steps: {
      initial: 'innerSteps',
      id: 'steps',
      // hide any modal when starting the flow, except for the error modal
      entry: choose([
        {
          actions: { type: 'changeModal', modal: '' },
          cond: (ctx: FormContext) => ctx.activeModal !== 'error',
        },
      ]),

      on: {
        NAVIGATE_TO_HOME: { target: 'idle' },
        SAVE: { actions: 'saveProgress' },
        RETURN_HOME: {
          target: '#mainMachine.init',
          actions: { type: 'resetForm' },
        },
      },
      states: {
        getAgentInfo: {
          invoke: {
            id: 'getAgentInfo',
            autoForward: true,
            src: agentInfoMachine,
            onDone: [
              {
                target: 'innerSteps.homeType.hist',
                cond: 'buyFromKWAgent',
                actions: [
                  assign({
                    answers: context => ({
                      ...context.answers,
                      buyOrRefinance: BuyOrRefinance.BUY,
                    }),
                  }),
                  'setAgentAnswer',
                ],
              },
              {
                target: 'innerSteps.homeType.hist',
                cond: 'buyDropdown',
                actions: 'setAgentAnswer',
              },
              {
                target: 'innerSteps.whatIsYourCurrentAddress.hist',
                cond: 'refinanceDropdown',
                actions: 'setAgentAnswer',
              },
              {
                target: 'innerSteps.hist',
                cond: 'resumeFlow',
                actions: 'setAgentAnswer',
              },
              {
                target: 'innerSteps',
                actions: ['setAgentAnswer', 'resetFlowCompleted'],
              },
            ],
          },
        },
        innerSteps: {
          id: 'innerSteps',
          initial: 'buyOrRefinance',
          states: {
            buyOrRefinance: { ...buyOrRefiNode },
            homeType: { ...homeTypeNode },
            propertyUse: { ...propertyUseNode },
            propertyAddress: { ...propertyAddressNode },
            purchaseDate: { ...purchaseDateNode },
            askingPrice: { ...askingPriceNode },
            currentAddress: {},
            getUserInfo: getUserInfoNode,
            whatIsYourCurrentAddress: { ...whatIsYourCurrentAddressNode },
            doesThisLookRight: {
              ...doesThisLookRightNode,
            },
            disclaimer: disclaimerNode,
            quotes: quotesNode,
            submitData: submitDataNode,
            hist: {
              type: 'history',
              history: 'deep',
            },
          },
        },
        finished: {
          id: 'finished',
          entry: ['redirect', 'setFlowCompleted'],
        },
        hist: {
          type: 'history',
          history: 'deep',
        },
      },
    },
    invalidPartner: {
      id: 'invalidPartner',
      entry: [{ type: 'changeModal', modal: '' }],
    },
  },
  on: {
    CHANGE_MODAL: { actions: 'changeModal' },
    RETURN_NO_AGENT: {
      target: '#steps.getAgentInfo',
      actions: 'setIsNoAgentReturn',
    },
    CLEAR_LAST_STEP: {
      actions: assign({ lastState: _context => '' }),
    },
    ClOSE_MODAL_AND_GO_LAST_STEP: {
      actions: { type: 'changeModal', modal: '' },
      target: '#innerSteps.hist',
    },
    CLEAR_ANSWERS: { actions: 'clearAllAnswers' },
    SET_ORIGIN_BUY: {
      actions: ['setOrigin', 'setAnswer'],
      target: '#innerSteps.homeType.hist',
    },
    SET_ORIGIN_REFINANCE: {
      actions: ['setOrigin', 'setAnswer'],
      target: '#innerSteps.whatIsYourCurrentAddress.hist',
    },
    UPDATE_SESSION: { actions: 'updateSession' },
  },
};

const machineOptions: Partial<MachineOptions<FormContext, any>> = {
  actions: {
    changeModal: assign({
      activeModal: (_, event, { action }) => event.modal || action.modal || '',
      isLoadingToSplash: (_, event, { action }) =>
        event.toSplash || action.toSplash || false,
    }),
    resetForm: assign((context, _event, { action }) => ({
      ...formContext,
      flowInfo: {
        ...formContext.flowInfo,
        flowCompleted:
          action.flowCompleted !== undefined
            ? action.flowCompleted
            : context.flowInfo.flowCompleted,
      },
    })),
    setAgentAnswer: assign({
      flowInfo: (context, event) => {
        let finalCampaignid = context.flowInfo.campaignID;
        if (event.data && !context.flowInfo.urlHasCampaignId) {
          finalCampaignid = event.data.campaignID;
        }

        return {
          ...context.flowInfo,
          campaignID: finalCampaignid,
          origin: Origin.ORGANIC,
        };
      },
      answers: (context, event) => ({
        ...context.answers,
        getAgentInfo: event.data
          ? event.data.answer
          : context.answers.getAgentInfo,
      }),
      agent: (context, event) =>
        event.data ? event.data.selectedAgent : context.agent,
      marketCenter: (context, event) =>
        event.data ? event.data.marketCenter : context.marketCenter,
    }),
    setIsNoAgentReturn: assign({
      lastState: (_context, event) => event.lastState,
    }),
    setAddress: assign({
      address: (_context, event) => event.address,
    }),
    clearAddress: assign({
      address: (context, _event) => {
        return { ...formContext.address, unit: context.address.unit };
      },
    }),
    clearAddressAndUnit: assign({
      address: (_context, _event) => ({ ...formContext.address }),
    }),
    setUnit: assign({
      address: (context, event) => ({
        ...context.address,
        unit: event.unit,
      }),
    }),
    clearAllAnswers: assign(context => ({
      ...formContext,
      flowInfo: {
        ...context.flowInfo,
        flowCompleted: false,
        isLeadSubmitted: false,
      },
      utm: context.utm,
    })),
    setOrigin: assign((context, event) => ({
      ...context,
      flowInfo: {
        ...context.flowInfo,
        origin: event.origin,
      },
    })),
    setAnswer: assign({
      answers: (context, event) => ({
        ...context.answers,
        [event.property]: event.value,
      }),
    }),
    redirect: context => {
      const redirectURL = context.redirectUrl;
      if (redirectURL && typeof window !== 'undefined') {
        window.location = redirectURL;
      }
    },
    setFlowCompleted: assign({
      flowInfo: (context, _event) => ({
        ...context.flowInfo,
        flowCompleted: true,
      }),
    }),
    resetFlowCompleted: assign({
      flowInfo: (context, _event) => ({
        ...context.flowInfo,
        flowCompleted: false,
      }),
    }),
    updateSession: async (context, _event, actionMeta) => {
      if (context.flowInfo.sessionID) {
        await getOrUpdateSessionId(context, actionMeta);
      }
    },
    saveProgress: assign({
      saveProgress: _ctx => spawn(saveProgressMachine, { sync: true }),
    }),
    ...initializingActions,
    ...homeTypeActions,
    ...propertyUseActions,
    ...buyOrRefiActions,
    ...propertyAddressActions,
    ...purchaseDateActions,
    ...askingPriceActions,
    ...doesThisLookRightActions,
    ...getUserInfoActions,
    ...disclaimerActions,
    ...submitDataActions,
    ...quotesActions,
  },
  guards: {
    buyFromKWAgent: (context, event) =>
      event.data.answer === 'buying from KW agent' &&
      (context.answers.buyOrRefinance === 'refinance' ||
        context.answers.buyOrRefinance === ''),
    refinanceDropdown: context =>
      context.flowInfo.origin === 'refinanceDropdown',
    buyDropdown: context => context.flowInfo.origin === 'buyDropdown',
    resumeFlow: context => !context.flowInfo.flowCompleted,
    validAddress: (_context, event) => {
      const { address } = event;
      return address.address && address.state && address.zip.number;
    },
    ...initializingGuards,
    ...getUserInfoGuards,
    ...disclaimerGuards,
    ...submitDataGuards,
    ...quotesGuards,
  },
};

export const mainMachine = createMachine<FormContext, FormEvent>(
  mainMachineConfig,
  machineOptions
);
